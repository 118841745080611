/* Certificates.module.css */
@media screen and (max-width: auto) {
  /* Styles for screens up to 768px wide */

.certificates{
  /* border:2px solid red; */
 margin-left: 30%;
 padding:10px 40px 0px 40px;
 width:fit-content;

}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  /* border:2px solid red; */
  
}

.certificatesImage {
  max-width: 70%;
  max-height: 100%;
  /* border:2px solid blue; */

}

.importanceContent {
  max-width: 50%;
  /* margin-top: 5px; */
  text-align: center;
  /* border:2px solid blue */
}

.importanceHeading {
  font-size: 24px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.importanceText {
  font-size: 18px;
  line-height: 1.5;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap");

.cert-container
{
  background-color: rgba(1, 4, 14, 0.2);
 
 max-width: auto;
 margin:40px 40px 40px 40px;
 padding:40px 40px;

}
.cert-container h1{
  text-align: center;
  font-weight: bolder;
  font-family: 'Times New Roman', Times, serif;
}

.item{
  padding-left:5px;
  padding-right:17px;
  
}
.item-card{
  transition:0.5s;
  cursor:pointer;
}


.card:hover{
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
}
.card-text{
  height:170px;  
}

.card::before, .card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}
.card::before {
  transform-origin: left top;
}
.card::after {
  transform-origin: right bottom;
}
.card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
  transform: scale3d(1, 1, 1);
}

}