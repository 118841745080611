
.logo img {
  height: 60px;
    width: 200px;
  border-radius: 40px;
    margin-bottom: 40px;
    margin-left: 110px;
    box-shadow: rgba(210, 10, 130, 0.2) 4px 5px 25px 5px;
  }
.form {
  width: 500px;
  height: auto;
  border-radius: 10px;
  margin: auto;
  align-items: center;
  padding: 30px;
  box-shadow: rgba(164, 8, 91, 0.2) 0px 8px 24px;
  background-color: whitesmoke;
  margin-top: 50px;
  margin-bottom: 50px;
}

.signup input[type="url"],
input[type="text"],
input[type="number"],
select,
textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
.form input[type="submit"] {
  background:#002147;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
  font-size: 20px;
  margin-left: 30%;

}
.form input[type="submit"]:hover {
  background-color: rgb(53, 53, 53);

}
  

@media only screen and (max-width: 480px) {
  .logo img {
   
    margin-left:50px;
   
  }
 
  .form{
    width:auto;;
    margin: auto;
    margin-top: 50px; 
    margin-bottom: 50px;
  }
  }  
  