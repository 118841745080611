#Navbar {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 5px;
  background-color: white;
}

#Navbar a {
  text-decoration: none;
  font-size: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: #002147;
}

#Navbar #logo img {
  height: 50px;
  width: 60px;
  box-shadow: rgba(0, 8, 8, 0.918) 2px 2px 20px 2px;
  border-radius: 50%;
  color: rgba(7, 182, 191, 0.918);
  transition: all 0.3s ease;
}

/* #chitr {
  height: 40px;
  width: 150px;
  border-radius: 50px;
  box-shadow: rgba(4, 84, 131, 0.2) 4px 5px 25px 5px;
} */

#Navbar #rightBox {
  width: 600px;
  display: flex;
  position: relative;
  top: 20px;
  flex-direction: row-reverse;
  justify-content: space-around;
  font-weight: bold;
}


/* @media screen and (width: 100px) {
  #Navbar {
    width: 40px;
  } */
@media screen and (max-width: 768px) {
  #Navbar {
    flex-direction: column;
    align-items: center;

    text-align: center;
  }

  #Navbar #logo {
    margin-bottom: 10px;
  }

  #Navbar #rightBox {
    width: 100%;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (min-width: 360px) and (max-width: 1024px) {
  #Navbar {
   width:auto;
  font-size: 14px;
  }
  
}
