
#container{

  display: grid;;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  padding:40px;
   margin:auto;

}
#container #box{

   height:auto;
   /* background-color:white; */
   /* background: "rgb(15, 130, 176)"; */
   width:auto;
   text-align: center;
   padding:20px;
   padding-bottom: 40px; 
   border-radius: 20px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#container img{
  border: "2px solid red";
   height:220px;
   width:100%;
   border-radius: 10px;

}

#box>p{
  /* border: 1px solid red; */
  Height: 75px;
  overflow: hidden;

}
#btndiv{
  width: 100px;

}

button{
  padding:10px;
  width:auto;
  color:white;
  background-color: rgb(15, 130, 176);
  font-size: 17px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
 

  
}
button:hover{
background-color: rgb(169, 155, 155);
}

      
#filterSort{
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  
}

.desc{
 min-height: 80px;
display: -webkit-box; 
-webkit-line-clamp: 3; 
-webkit-box-orient: vertical;
overflow: hidden; 
}

/* responsive part start header */


/* @media only screen and (max-width: 1100px){
#Navbar{
  width:1160px;
  text-align: center;
 
 }
 #container{
   grid-template-columns: repeat(3, 1fr);
   column-gap: 20px;
 } 
 #Navbar #rightBox{
  align-items: center;
 }
} */

@media only screen and (max-width: 700px) {
#Navbar{
width:760px;
text-align: center;
}
#container{
grid-template-columns: repeat(2, 1fr);
column-gap: 20px;
}
#Navbar #rightBox{
align-items: center;
}
}


@media only screen and (max-width:400px) {
#Navbar{
width:400px;
text-align: center;

}
#container{
grid-template-columns: repeat(1, 1fr);
}

#container #box{
width:300px;
margin:auto;
}
#Navbar #rightBox{
margin-left: 0px;
}

.img-div{
width:500px;
}

#footer{
wodth:400px;
}


}  


.image-row {
  /* border:2px solid red; */
  display: grid;;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 10px;
  padding:10px;
   margin:auto;
}

.img_data {
  height:auto;
  /* background-color:white; */
  /* background: "rgb(15, 130, 176)"; */
  width:350px;
  text-align: center;
  padding:20px;
  padding-bottom: 40px; 
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.img_data img {
  border: "2px solid red";
  height:220px;
  width:100%;
  border-radius: 10px;
}
