*{
  margin: 0;
  padding: 0;
  /* border:5px solid red; */
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap");

:root {
  --color-gray: #515151;
  --color-black: #000;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.form-main {
  background: linear-gradient(to bottom, #00000024, #00000024),
    url(https://res.cloudinary.com/dqifboxk5/image/upload/v1686209968/contact-us-2_y8zzx7.jpg)
      no-repeat center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-wrapper {
  border-radius: 10px;
  padding: 45px;
  width: 40%;
  box-shadow: 0 0 5px 5px #00000020;
  backdrop-filter: blur(5px);
  background-color: #ffffff85;
}

@media screen and (max-width: 991px) {
  .main-wrapper {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    width: auto;
  }
}

.form-head {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 25px;
}

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.form-card {
  position: relative;
  width: 100%;
}

.form-input {
  padding: 20px 25px 15px;
  width: 100%;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  background: transparent;
  outline: none;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  box-sizing: border-box;
}

.form-input:valid,
.form-input:focus {
  border: 1px solid var(--color-gray);
}

.form-input:valid ~ .form-label,
.form-input:focus ~ .form-label {
  color: var(--color-gray);
  top: 30%;
  transform: translateY(-70%);
  font-size: 13px;
  line-height: 23px;
}

.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

.form-input::-webkit-outer-spin-button,
.form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-label {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: 0.3s;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.form-textarea {
  padding: 20px 25px 15px;
  width: 100%;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  background: transparent;
  outline: none;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
}

.form-textarea:valid,
.form-textarea:focus {
  border: 1px solid var(--color-gray);
}

.form-textarea:valid ~ .form-textarea-label,
.form-textarea:focus ~ .form-textarea-label {
  color: var(--color-gray);
  top: 18%;
  transform: translateY(-82%);
  font-size: 13px;
  line-height: 23px;
}

.form-textarea:-webkit-autofill,
.form-textarea:-webkit-autofill:hover,
.form-textarea:-webkit-autofill:focus,
.form-textarea:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

.form-textarea-label {
  position: absolute;
  left: 25px;
  top: 30%;
  transform: translateY(-70%);
  pointer-events: none;
  transition: 0.3s;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0;
}

.btn-wrap button {
  padding: 0 32px;
  font-size: 18px;
  line-height: 48px;
  border: 1px solid transparent;
  font-weight: 600;
  border-radius: 6px;
  transition: all 0.5s ease;
  cursor: pointer;
  box-shadow: 0 0 5px 5px #00000020;
}

.btn-wrap button:hover {
  border: 1px solid #000;
  background: transparent;
}

.about-us-container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
}

.about-us-heading {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
  /* color:#000 */
}

.about-us-text {
  line-height: 1.6;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.sub_heading{
  color:#0e3689;
  font-weight:bold;
}

.singleData_product{
  font-size: 20px;
  margin-right: 20px;
 font-weight: bold;
 color:#0e3689;


}
/* WhatsApp icon styles */
.whatsapp_icon {
  margin-top: 0;
}

/* Media query for smaller screens */
@media screen and (max-width: 767px) {
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }

  .whatsapp_icon {
    margin-top: 0;
  }
}
.navbar-top {
  background-color: #f9f9f9;
  padding: 10px 0;
}

.navbar-top .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-top ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-top ul li {
  display: inline-block;
  margin-right: 20px;
}

.navbar-top p {
  margin: 0;
}

.navbar-top a {
  color: #333;
  text-decoration: none;
  margin-right: 10px;
}

.navbar-top a:hover {
  color: #000;
}

.text-md-left {
  text-align: left;
}

.text-md-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.col-md-4 {
  display: flex;
  align-items: center;
}

.image-row {
  /* border:2px solid red; */
  display: grid;;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  column-gap: 10px;
  padding:10px;
   /* margin:auto; */
    margin:20px;
  
}

.img_data {
  height:auto;
  /* background-color:white; */
  /* background: "rgb(15, 130, 176)"; */
  /* width:350px; */
  text-align: center;
  padding:20px;
  padding-bottom: 40px; 
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.img_data img {
  border: "2px solid red";
  height:220px;
  width:100%;
  border-radius: 10px;
}

.client_section .heading_container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.client_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin: 45px;
  border-radius: 15px;
}

.client_section .box .img-box {
  margin-bottom: 15px;
  min-width: 145px;
  max-width: 145px;
  overflow: hidden;
}

.client_section .box .img-box img {
  width: 100%;
  border-radius: 100%;
  border: 7px solid rgba(255, 255, 255, 0.45);
}

.client_section .box .detail-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.client_section .box .detail-box .name img {
  width: 25px;
  margin-bottom: 5px;
}

.client_section .box .detail-box .name h6 {
color: #0e3689;
  font-size: 20px;
}

.client_section .carousel_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.client_section .carousel-control-prev,
.client_section .carousel-control-next {
  position: unset;
  width: 45px;
  height: 45px;
  border: none;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  background-color: #62d2a2;
  background-position: center;
  border-radius: 5px;
  margin: 0 2.5px;
 background: #0e3689;
}

.client_section .carousel-control-prev:hover,
.client_section .carousel-control-next:hover {
  background-color: #0e3689;
}

.client_section .carousel-control-next {
  left: initial;
}

/* Images ticker css */

#ticker-container {
  overflow: hidden;
  white-space: nowrap;
  /* background-color: blue; */
}

#ticker-content {
  display: inline-block;
  animation: tickerAnimation 4s linear infinite;
}

@keyframes tickerAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

#ticker-content img{
  margin:4px
}


.doctor_section {
  background:#0e3689;
  padding:40px;
}

.doctor_section .heading_container {
  color: #ffffff;
}

.margin_top_25{
  margin-top:25px;
}




@media screen and (min-width: 350px) and (max-width: 768px) {
  .image-row {
    /* border:2px solid red; */

    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
   
  }
 
  }

  @media screen and (min-width: 700px)and (max-width: 790px) {
  .image-row {
    /* border:2px solid red; */

    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
   
  }
}


 



  




