.logo img {
      height: 80px;
      width: 250px;
      border-radius: 100px;
      margin-bottom: 40px;
      /* margin-left: 90px; */
      box-shadow: rgba(2, 79, 155, 0.2) 4px 5px 25px 5px;
    }
  .form {
    width: 500px;
    height: auto;
    border-radius: 10px;
    margin: auto;
    align-items: center;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: whitesmoke;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .form input[type="url"],
  input[type="text"],
  input[type="number"],
  select,
  textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }

  .form input[type="submit"] {
    background:#002147;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    font-size: 20px;
    margin-left: 30%;
    margin-top: 30px;
  
  }

  .form input[type="submit"]:hover{
    background-color: rgb(53, 53, 53);
  
  }

  #container{

    display: grid;;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    column-gap: 0px;
    padding:40px;
     margin:auto;
  
  }
  #container #box{
     height:auto;
     background-color:white;
     width:320px;
     text-align: center;
     padding:20px;
     padding-bottom: 40px; 
     border-radius: 20px;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     
  }
  
  #container img{
    border: "2px solid red";
     height:220px;
     width:100%;
     border-radius: 10px;
  
  }
  
  #box>p{
    /* border: 1px solid red; */
    Height: 75px;
    overflow: hidden;

  }

    /* responsive part start header */


 @media only screen and (max-width: 1100px){
  #Navbar{
    width:1160px;
    text-align: center;
   
   }
   #container{
     grid-template-columns: repeat(3, 1fr);
     column-gap: 20px;
   } 
   #Navbar #rightBox{
    align-items: center;
   }
}

@media only screen and (max-width: 768px) {
#Navbar{
 width:760px;
 text-align: center;
}
#container{
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
#Navbar #rightBox{
  align-items: center;
 }
}

@media only screen and (max-width: 480px) {
#Navbar{
 width:400px;
 text-align: center;

}

#container{
  grid-template-columns: repeat(1, 1fr);
 column-gap: 20px;
}
.form{
  width:350px;
  margin: auto;
  margin-top: 30px; 
}

#container #box{
  width:330px;
}
#Navbar #rightBox{
 margin-left: 0px;
 }

}  